import { Application } from "@hotwired/stimulus"

const application = Application.start()

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }


// setTimeout(() => {
// 				document.getElementById('splash').classList.toggle('fade');
// 			}, 2000);
