import { Controller } from "@hotwired/stimulus";


// Connects to data-controller="loading"
export default class extends Controller {
  static targets = ["form", "spinner"];

  connect() {}

  generate(e) {
    e.preventDefault();
    this.spinnerTarget.classList.remove("d-none");
    loadingMessage
      .typeString("Loading booking....")
      .pauseFor(4000)
      .deleteAll()
      .typeString("Saving your choice....")
      .pauseFor(4000)
      .deleteAll()
      .typeString("Almost done loading....")
      .pauseFor(4000)
      .deleteAll()
      .typeString("Generating next forms...")
      .pauseFor(8000)
      .deleteAll()
      .typeString("Almost done...")
      .pauseFor(20000)
      .deleteAll()
      .start();

    const url = this.formTarget.action;

    fetch(url, {
      method: "POST",
      body: new FormData(this.formTarget),
    }).then((response) => {
      console.log(response);
      console.log(response.url);
      if (response.ok) {
        window.location.href = response.url;
      } else {
        this.spinnerTarget.classList.add("d-none");
      }
    });
  }
}
