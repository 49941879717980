import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = ["names", "namecaret"]

  connect() {
  }
  revealNames() {
    this.namesTarget.classList.toggle("d-none")
    this.namecaretTarget.classList.toggle("fa-caret-down")
  }
}

